















































import { Component, Prop } from 'vue-property-decorator'
import { VTextField, VTextarea } from 'vuetify/lib'
import FormBase from '@/components/formBase.vue'

@Component({
  inheritAttrs: false,
})
export default class KInput extends FormBase {
  @Prop({ type: String, required: false, default: 'input' })
  tag!: 'input' | 'textarea'

  @Prop({ type: String, default: 'top' })
  readonly labelPosition!: 'top' | 'inside'

  @Prop({
    type: Number,
    default: 0,
  })
  readonly inputWidthOffset!: number

  @Prop({
    type: String,
    required: false,
  })
  validation!: string | undefined

  @Prop({
    type: String,
    default: '',
  })
  regex!: string

  @Prop({
    type: String,
    default: '',
  })
  prefix!: string

  @Prop({
    type: String,
    default: '',
  })
  suffix!: string

  @Prop({
    type: Boolean,
    default: false,
  })
  secret!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  showSecret!: boolean

  $refs!: {
    input: HTMLFormElement;
  }

  get vuetifyTag() {
    switch (this.tag) {
      case 'input':
        return VTextField
      case 'textarea':
        return VTextarea
      default:
        return VTextField
    }
  }

  get rules() {
    const result = []
    if (this.validation) {
      result.push(this.validationRule)
    }
    return result
  }

  validationRule(value: string): boolean | string {
    if (!this.validation) {
      return true
    }
    const validations: Record<string, any> = {
      alpha: (value: string): boolean | string => /^[a-zA-Z]*?$/.test(value) || 'La valeur ne doit contenir que des lettres',
      alphanum: (value: string): boolean | string => /^[a-zA-Z0-9]*?$/.test(value) || 'La valeur ne doit contenir que des lettres ou des chiffres',
      num: (value: string): boolean | string => /^\d*?$/.test(value) || 'La valeur ne doit contenir que des chiffres',
      custom: (value: string): boolean | string => new RegExp(this.regex).test(value) || 'La valeur ne respecte pas le schéma défini',
    }
    return validations[this.validation](value)
  }

  // onInput (newvalue: string) {
  //   this.$emit('input', newvalue)
  // }

  // TODO That really ugly
  // @Watch('inputWidthOffset', { immediate: true })
  // onInputWidthOffsetChanged (offset: number) {
  //   this.$nextTick(() => {
  //     if (this.$refs.input) {
  //       const input = this.$refs.input.$refs.input
  //       if (input) {
  //         const inputWidth = input.getBoundingClientRect().width
  //         input.style.maxWidth = `${inputWidth - offset}px`
  //       }
  //     } else {
  //       console.error('Ref not found')
  //     }
  //   })
  // }
}
