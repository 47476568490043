











































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AutocompleteItem } from '@knitiv/api-client-javascript'
import { InjectAPI } from '@/utils/api'
import { HighlightedObject } from '@/utils/fuzzy'

@Component({
  mixins: [InjectAPI],
})
export default class MfTree extends Vue {
  @Prop({
    required: true,
    default: [],
    type: Array,
  })
  roots!: HighlightedObject<AutocompleteItem>[]

  @Prop({
    required: true,
    default: [],
    type: Array,
  })
  selected!: AutocompleteItem[]

  @Prop({
    required: true,
    type: String,
  })
  rootId!: string;

  get items() {
    if (this.roots.length === 0) {
      // @ts-ignore
      this.roots.push({
        selectable: 0,
        name: 'Aucun élément trouvé',
      })
    }
    return this.roots
  }

  isSelected(item: AutocompleteItem) {
    // eslint-disable-next-line
    return !!this.selected.find(selection => selection.kid === item.kid)
  }

  async loadChildren(item: AutocompleteItem) {
    const children = await this.$api.getMfChildren({
      rootId: this.rootId, // KNODE.SYS_CLASSE,
      classId: item.kid,
    })

    if (!children.result || !Array.isArray(children.result) || children.result.length === 0) {
      console.log('adding empty')
      // @ts-ignore
      item.child.push({
        name: 'Aucun élément trouvé',
        kid: 0,
        selectable: 0,
      })
    } else {
      // @ts-ignore
      // eslint-disable-next-line
      item.child.push(...children.result.map(this.addChild))
    }
  }

  addChild(x: AutocompleteItem) {
    if (x.children > 0) {
      // @ts-ignore
      x.child = []
    }
    return x
  }

  selectItem(item: AutocompleteItem) {
    this.$emit('selection', item)
  }
}
