
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FormBase extends Vue {
  @Prop({ type: String, required: false })
  readonly label!: string | undefined

  @Prop()
  readonly value!: any

  @Prop({ type: String, required: false })
  readonly tooltip!: string | undefined;

  @Prop({ type: String, required: false })
  readonly placeholder!: string | undefined;

  @Prop({ type: Boolean, required: false, default: false })
  readonly hideLabel!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly hidden!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly required!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  readonly submit!: boolean;
}
