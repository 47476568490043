













































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import { AutocompleteItem, Kid } from '@knitiv/api-client-javascript'

import Fuse from 'fuse.js'
import { debounce } from 'lodash'
import MfTree from '@/components/mf/mftreeview.vue'
import { N_MAX_CARDINALITY } from '@/constants/app'
import { VueRefs, WithRequired } from '@/models/util'
import KInput from '@/components/knitiv/kinput.vue'
import { InjectAPI } from '@/utils/api'
import FormBase from '@/components/formBase.vue'
import { HighlightedObject, highlightElement } from '@/utils/fuzzy'

// TODO mf with only custom elements

@Component({
  components: {
    MfTree,
    KInput,
  },
  mixins: [
    InjectAPI,
  ],
})
export default class Mf extends FormBase {
  $refs!: VueRefs<{
    mf: Vue;
  }>

  loading = false

  roots: HighlightedObject<AutocompleteItem>[] = []

  extraItems: AutocompleteItem[] = []

  menu = false

  skipNextWatch = false

  // selectedItems: AutocompleteItem[] = []

  get selectedItems() {
    return this.value
  }

  set selectedItems(val) {
    this.$emit('input', val)
  }

  get filteredRoots() {
    // allowlist > denylist
    let result: (HighlightedObject<AutocompleteItem> | AutocompleteItem)[] = this.roots

    result.push(...this.extraItems)
    result.push(...this.customValues)

    if (this.allowlist.length > 0) {
      result = result.filter((element) => {
        console.log(`including ${element.kid} ${this.allowlist.includes(element.kid)}`)
        return this.allowlist.includes(element.kid)
      })
    }
    if (this.denylist.length > 0) {
      result = result.filter((element) => {
        console.log(`not including ${element.kid} ${this.denylist.includes(element.kid)}`)
        return !this.denylist.includes(element.kid)
      })
    }

    console.log('result', result)

    result = result.filter((v, i, a) => a.findIndex((t) => (t.kid === v.kid)) === i)

    return result
  }

  @Prop({
    required: false,
    type: Array,
    validator(value: any[]): boolean {
      if (!Array.isArray(value)) {
        console.error('Value is not an array!')
        return false
      }

      let index = 0
      if (!value.every((val, i) => {
        index = i
        return ('kid' in val && 'name' in val && 'isa' in val)
      })) {
        console.error(`Value at index ${index} is missing either 'kid', 'name' or isa' property`, value)
        return false
      }
      return true
    },
    default: () => ([]),
  })
  value!: WithRequired<AutocompleteItem, 'kid' | 'name' | 'isa'>[]

  /**
   * La cardinalité gauche
   */
  @Prop({
    type: Number,
    required: true,
    validator(value: number): boolean {
      return value >= 0
    },
  })
  cardFrom!: number

  /**
   * La cardinalité droite
   */
  @Prop({
    type: Number,
    required: true,
    validator(value: number): boolean {
      return value >= 1
    },
  })
  cardTo!: number

  /**
   * La liste des order utilisées
   */
  @Prop({
    type: Array,
    required: true,
  })
  order!: Kid[]

  /**
   * Le placeholder utilisé dans l'input
   */
  @Prop({
    type: String,
    required: true,
  })
  placeholder!: string

  /**
   * Cache les détails en dessous de l'input
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hideHint!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  create!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  resetOnSelection!: boolean

  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  denylist!: string[]

  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  allowlist!: string[]

  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  customValues!: AutocompleteItem[]

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  default!: string;

  inputValue = ''

  rules = {
    too_much_items: () => this.selectedItemsAmount <= this.availableSelection || 'Trop d\'éléments sélectionnés!',
  }

  get classId(): string {
    if (this.order.length >= 2) {
      return `NODE_LIST:${this.order.join(',')}`
    }
    return this.order[0]
  }

  get multiple() {
    let left = this.cardFrom
    if (this.cardFrom === 0) {
      left = 1
    }
    return this.cardTo - left > 0
  }

  get selectedItemsAmount() {
    return this.selectedItems.length
  }

  get availableSelection() {
    return this.cardTo - this.cardFrom + 1
  }

  get hint() {
    //                           Set a minimum of 1
    const max = this.cardTo - (this.cardFrom === 0 ? 1 : this.cardFrom) + 1
    return `${this.selectedItemsAmount} / ${(max) === N_MAX_CARDINALITY ? '∞' : max}`
  }

  onInput = debounce((query: string) => {
    if (!this.multiple) {
      this.$delete(this.selectedItems, 0)
    }

    this.inputValue = query

    console.log('this', this)

    console.log('this.inputValue', this.inputValue)

    if (query?.length >= 2 && !this.skipNextWatch) {
      this.autocomplete(query)
    }
    this.skipNextWatch = false
  }, 200)

  onUpload() {
    //
  }

  createNode(search: string) {
    console.log(`Creating ${search}`)
  }

  addChild(x: AutocompleteItem) {
    if (x.children > 0) {
      // @ts-ignore
      x.child = []
    }
    return x
  }

  async autocomplete(query: string) {
    this.loading = true

    try {
      let result: AutocompleteItem[] = []
      if (this.classId !== 'NONE') {
        const response = await this.$api.autocomplete({
          query,
          order: this.classId,
        })

        result = response.result
      }

      const options = {
        includeScore: true,
        shouldSort: true,
        includeMatches: true,
        findAllMatches: true,
        keys: [
          'kid',
          'name',
        ],
      }

      const elements = result.map((element) => this.addChild(element))

      const fuse = new Fuse(elements, options)

      const searchResult = fuse.search(query)

      console.log('searchResult', searchResult)

      const searchResultRoots = searchResult.map((element) => highlightElement(element))

      // eslint-disable-next-line
      this.roots = searchResultRoots
      console.log('this.roots', this.roots)
    } catch (e) {
      console.error(e)
      console.error(`Impossible de récupérer les données d'autocomplétions de ${this.classId}`)
    }

    this.loading = false

    this.menu = true
  }

  @Watch('value', { deep: true })
  onValuesChanged() {
    this.setBaseInputText()
  }

  addItem(item: AutocompleteItem) {
    // eslint-disable-next-line
    // @ts-ignore
    this.extraItems.push(this.addChild(item))
  }

  async suggest(showMenu = true) {
    console.log('this.roots.length', this.roots.length)
    console.log('showMenu', showMenu)
    if (this.roots.length > 0 && showMenu) {
      this.menu = true
    }

    this.loading = true

    try {
      let result: AutocompleteItem[] = []
      if (this.classId !== 'NONE') {
        const response = await this.$api.suggest({
          order: this.classId,
        })
        result = response.result
      }
      // eslint-disable-next-line
      // @ts-ignore
      this.roots = [
        ...result.map((item) => this.addChild(item)),
      ]
    } catch {
      console.error(`Impossible de récupérer les données de suggestion de ${this.classId}`)
    }

    this.loading = false

    this.menu = showMenu ? true : this.menu

    return this.roots
  }

  removeChip(item: AutocompleteItem) {
    if (!this.selectedItems) {
      return
    }
    const index = this.selectedItems.findIndex((i) => i.kid === item.kid)
    if (index >= 0) {
      this.selectedItems.splice(index, 1)
    }
  }

  onTreeSelection(selection: AutocompleteItem) {
    this.skipNextWatch = true

    if (!this.multiple) {
      this.$set(this.selectedItems, 0, selection)
      this.inputValue = selection.name
      console.log('this.inputValue', this.inputValue)
    } else if (this.selectedItems.length < this.availableSelection) {
      this.selectedItems.push(selection)
      this.inputValue = ''
      console.log('this.inputValue', this.inputValue)
    } else {
      this.$toast.error(`Impossible d'ajouter plus d'élements: Vous avez déjà atteint la limite de ${this.availableSelection} sélections`)
    }

    this.$emit('selection', selection)
    console.log('this.resetOnSelection', this.resetOnSelection)
    if (this.resetOnSelection) {
      this.$delete(this.selectedItems, 0)
    }
    // this.$emit('input', this.selectedItems)
  }

  // create () {
  //   if (!this.selectedItems || !Array.isArray(this.selectedItems)) {
  //     this.selectedItems = []
  //   }
  // }

  setBaseInputText() {
    if (this.value.length > 0) {
      if (!this.multiple && this.value.length === 1) {
        this.inputValue = this.value[0].name
      } else if (this.multiple && this.value.length >= 2) {
        this.inputValue = ''
      }
    }
  }

  async mounted() {
    // Set native input attributes to mf input
    const el = this.$refs.mf.$el
    const input = el.querySelector('input')
    if (input) {
      input.setAttribute('autocomplete', 'off')
    }

    this.value.forEach((val, index) => {
      if (!this.order.includes(val.isa)) {
        console.error(`Value at index ${index} does not match specified order: ${this.order.join(', ')}. Found ${val.isa} instead`)
      }
    })

    const suggest = await this.suggest(false)
    if (this.default) {
      const defaultValue = suggest.find((s) => s.kid === this.default)
      if (defaultValue) {
        this.onTreeSelection(defaultValue)
      }
    }

    // Set initial input value
    this.setBaseInputText()
  }
}
